import React, { useContext, useEffect } from "react";
import { Row, Col } from "antd";
import StakeCard from "../../layout/stakeCard/StakeCard";
import RevenueClaim from "../../layout/tokenClaim/RevenueClaim";
import TokenClaim from "../../layout/tokenClaim/TokenClaim";
import "./HomeStyle.scss";
import { MetaProvider } from "../../hooks/networkContext";
import { useDispatch, useSelector } from "react-redux";
import {  fetchTokenClaim, ownerAddress, tokenClaimEmpty } from "../../features/address/addressSlice";
import { walletServices } from "../../../services/walletServices";
import { isMobile } from "react-device-detect";

const Home = () => {
  const dispatch = useDispatch();
  const {address} = useSelector(s=>s.address);
  useEffect(() => {
   
    document.body.classList.add("home");
    return () => document.body.classList.remove("home");
  });
  useEffect(() => {
    getToken();
  }, [address]);
  

  const getToken = async() =>{
    if(address){
      // This will work after changing network and address
      const account = await walletServices.isMetamaskInstalled();
      dispatch(ownerAddress(account));
      await dispatch(fetchTokenClaim(account));
    }
  }

  return (
    <div className="dashboard_page">
      <Row>
        <Col lg={12} md={24} sm={24} xs={24}>
          <StakeCard />
        </Col>
      </Row>
      <Row className="bottom_view">
        <Col lg={12} md={24} sm={24} xs={24}>
          <TokenClaim className="mangae_toknclm" />
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <RevenueClaim />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
