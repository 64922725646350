import { toast } from "../components/layout/Toasts/Toast";
import { provider, walletServices } from "./walletServices";
import web3 from 'web3';
import { tokenclaimData } from "../constants/abis";
import { MAX_AMOUNT, stakingAdr, tokenAdr } from "../constants";
import stakingabi from "../assets/abi/stakingContract.json";
import tokenabi from "../assets/abi/tokenContract.json";
import moment from "moment";
import { calc } from "../helpers";


const stakingInstance = async() => {
    let web3Obj = await walletServices.callWeb3();
    let stakeInstance = new web3Obj.eth.Contract(stakingabi, stakingAdr);
    return stakeInstance;
}

const claimToken = async(name, address, abi, lockPeriod, ownerAddress) => {
    try {
        let startTime = 1652252198;
        let currentTime = Math.round(new Date().getTime() / 1000);
        let lPeriod = 2629746;
        let amount = 0;
        let web3Obj = await walletServices.callWeb3();
        let contract = new web3Obj.eth.Contract(JSON.parse(JSON.stringify(abi)), address)
        let claimAmt;
        if ((startTime + lPeriod) < currentTime) {
            if (name == 'Liquidity') {
                claimAmt = await contract.methods.userInfo(ownerAddress).call();
                let launched = await contract.methods.unlock().call();
                if (claimAmt.claimed || !launched) {
                    amount = 0;
                }
                else {
                    amount = claimAmt.total - claimAmt.tgeAmount;
                }
                if (amount != 0) {
                    amount = (amount / Math.pow(10, 18)).toFixed(2);
                }
                else {
                    amount = 0;
                }
                return { amount, name, address: address, abi: abi };
            }
            if (name == 'Staking') {
                claimAmt = await contract.methods.userInfo(ownerAddress).call();
                amount = claimAmt.tokensAlotted;
                if (!claimAmt.claimed) {
                    if (amount != 0) {
                        amount = (amount / Math.pow(10, 18)).toFixed(2);
                    }
                    else {
                        amount = 0;
                    }
                } else {
                    amount = 0;
                }
                return { amount, name, address: address, abi: abi };
            }
            if (name == 'Nft') {
                claimAmt = await contract.methods.userInfo(ownerAddress).call();
                amount = claimAmt.tokensAlotted;
                if (!claimAmt.claimed) {
                    if (amount != 0) {
                        amount = (amount / Math.pow(10, 18)).toFixed(2);
                    }
                    else {
                        amount = 0;
                    }
                } else {
                    amount = 0;
                }
                return { amount, name, address: address, abi: abi };
            }
            if(name =="Advisory" || name =='Foundation' || name =="Treasury"){
                if((startTime + 31556952) >  currentTime){
                    return {amount, name, address: address, abi: abi}
                }
            }
            claimAmt = await contract.methods.claimableAmount(ownerAddress).call();
            amount = claimAmt
            
            if (amount != 0) {
                amount = (amount / Math.pow(10, 18)).toFixed(2);
            }
            else {
                amount = 0;
            }
            return { amount, name, address: address, abi: abi };
        }
    } catch (error) {
        let msg = error.message.split(',')[1];
        console.log(error)
        return false;
        toast.error(msg)
    }
}

const claimAmount = async ({ name, waddress }) => {
    try {
        let getabi = tokenclaimData[name].abi;
        let getadr = tokenclaimData[name].address;
        let web3Object = await walletServices.callWeb3();
        let scontract = new web3Object.eth.Contract(JSON.parse(JSON.stringify(getabi)), getadr);
        let result;
        if (name == 'Staking' || name == 'Nft') {
            let estimateGas = await scontract.methods.redeemTokens(waddress).estimateGas({ from: waddress });
            result = await scontract.methods.redeemTokens(waddress).send({ from: waddress, gas: estimateGas });
        } else {
            let estimateGas = await scontract.methods.claim(waddress).estimateGas({ from: waddress });
            result = await scontract.methods.claim(waddress).send({ from: waddress, gas: estimateGas });
        }
        return result;
    } catch (error) {
        // let msg = error.message.split(',')[1];
        console.log(error.message)
        toast.error(error.message)
        return undefined;
    }


}

const stakeApr = async () => {
    try {
        const stakeInstance = await stakingInstance();
        const result = await stakeInstance.methods.APY().call();
        return result;
    } catch (error) {
       
    }
    
}

const convertInNumber = (amount) =>{
    let value = 18;
    let nAmt = amount.toString()
    const index = nAmt.indexOf('.');
    const getNumber = nAmt.slice(index + 1);

    console.log(getNumber.length, index, getNumber, 'asdasdasd')
    let fAmt;
    let fval;
    if (getNumber.length > 0 && index != -1) {
      fAmt = amount * Math.pow(10, getNumber.length);
      fval = value - getNumber.length;
    }
    else {
      fAmt = amount;
      fval = value;
    }
    let amt = web3.utils
      .toBN((fAmt))
      .mul(web3.utils.toBN(Math.pow(10, fval)))
      .toString();
      return amt;
}

const staking = async (stakeAmt, waddress) => {
    try {
        if (stakeAmt == "") {
            throw { message: "Stake amount is required" };
        }
        let web3Obj = await walletServices.callWeb3();
        let tokenInstance = new web3Obj.eth.Contract(tokenabi, tokenAdr);
        let estimategas = await tokenInstance.methods.approve(stakingAdr, MAX_AMOUNT)
            .estimateGas({ from: waddress });
        let approvedTkn = await tokenInstance.methods.allowance(waddress, stakingAdr)
            .call();
        if (approvedTkn == 0) {
            await tokenInstance.methods.approve(stakingAdr, MAX_AMOUNT)
                .send({ from: waddress, gas: estimategas });
        }
        let stakeInstance = new web3Obj.eth.Contract(stakingabi, stakingAdr);
        const amt = convertInNumber(stakeAmt);
        console.log(amt,'amntamnt')
       
        const esGas = await stakeInstance.methods.stake(amt, "0").estimateGas({ from: waddress });
        const result = await stakeInstance.methods.stake(amt, "0").send({ from: waddress });
        return result;
    } catch (error) {

        console.log(error)
        toast.error(error.message);
    }

}

const getStakeCount = async(waddress) => {
    try {
        const stakeInstance = await stakingInstance();
        const getstaketime = await stakeInstance.methods.stakingsByUser(waddress).call();
        return getstaketime;
    } catch (error) {
        return
    }
    
}

const stakingDetail = async (waddress,getstaketime) => {
    const stakeInstance = await stakingInstance();
    const result = await stakeInstance.methods.stakingDetails(waddress, getstaketime).call();
    let getrewards = await stakeInstance.methods.calculateInterest(waddress,getstaketime,"0").call();

    let date1 = new Date();
    let date2 = new Date(Number(result.locktime)*1000);

    let stakeDate = moment(date2).format('DD:MM:YY');
    let claimDate = moment(date2).add(30, 'days').format('DD:MM:YY');

    var difference = date1.getTime() - date2.getTime();
    var daysDifference =30- Math.floor(difference/1000/60/60/24);
    let amount = result.stakedAmount/Math.pow(10,18);
    let time = "30 days";
    let rewardtime = daysDifference+" days";
    let rewards = getrewards;
    rewards= (rewards/10**18).toFixed(3); 

    let stakeData = {key:getstaketime, sNo:getstaketime,amount,time,rewardtime,rewards,stakeDate,claimDate,daysDifference}
    return stakeData;
}

const claimStakeAmt =async(waddress , id)=>{
    try {
        if(waddress ==""){
            return toast.error("Connect Wallet");
        }
        const stakeInstance = await stakingInstance();
        const estimateGas = await stakeInstance.methods.claimInterest(id).estimateGas({from:waddress});
        const result = await stakeInstance.methods.claimInterest(id).send({from:waddress,gas:estimateGas});
        return result;
    } catch (error) {
        console.log(error.message)
        let msg = error.message.split(',')[1];
        if(msg.includes("not yet")){
            toast.error('Tokens can be claimed after 30 days');
            return;
        }
        toast.error(msg)
    }
}

const unstakeAmt =async(id,waddress)=>{
    try {
        if(waddress ==""){
            return toast.error("Connect Wallet");
        }
        
        const stakeInstance = await stakingInstance();
        const estimateGas = await stakeInstance.methods.unStake(id).estimateGas({from:waddress});
        const result = await stakeInstance.methods.unStake(id).send({from:waddress,gas:estimateGas});
        return result;
    } catch (error) {
        let msg = error.message.split(',')[1];
        console.log(error)
        toast.error(msg)
    }
}


const maxToken = async(address) =>{
    try {
        let web3Obj = await walletServices.callWeb3();
        let tokenInstance = new web3Obj.eth.Contract(tokenabi, tokenAdr);
        const maxAmt = await tokenInstance.methods.balanceOf(address).call();
        let amt = web3.utils.fromWei(maxAmt);    
        amt = calc(amt);   
    return (amt)
    } catch (error) {
       
    }
}

export const ContractServices = {
    claimToken,
    claimAmount,
    staking,
    stakeApr,
    stakingDetail,
    getStakeCount,
    claimStakeAmt,
    unstakeAmt,
    maxToken
}