import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { chainID } from "../../../constants";
import { walletServices } from "../../../services/walletServices";
import { ownerAddress } from "../../features/address/addressSlice";

export const MetaProvider = React.createContext();

const NetworkContext = (props) => {
  useEffect(() => {
    // switchNetwork();
    trackNetwork();
   
  }, []);

 

  const switchNetwork = () => {
    const result = window.ethereum;
    if (result && window.ethereum?.networkVersion != chainID) {
      walletServices.networkSwitch();
    }
  };

  const trackNetwork=async()=>{
    await walletServices.accountsChanged()
  }

  return (
    <MetaProvider.Provider value={{ snetwork: switchNetwork }}>
      {props.children}
    </MetaProvider.Provider>
  );
};

export default NetworkContext;
