export const contentPop = (
    <div>
      <p>Claim/Unstake will get active once your staking time period will be over.</p>
    </div>
  );

  export const contentTknClaim = (
    <div>
      <p>This claim is only for seed and pre-sale investors.</p>
    </div>
  );

  export function calc(num) {  
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return with2Decimals;
}